import { ADMIN_ROLE } from 'constants/main';

export const viewUserProfile = (
  user,
  setCurrentUser,
  setModalType,
  setOpen
) => {
  setCurrentUser(user);
  setModalType('viewProfile');
  setOpen(true);
};

export const getUserRoleValue = (currentUser) => {
  if (currentUser.roleID === ADMIN_ROLE) return 'А';
  return 'П';
};

export const getCurrentUserBool = (currentUser, item) => {
  if (currentUser[item] === 1) return 'Да';
  return 'Нет';
};

export const getSorting = (sortDirection, fieldForSortBy) =>
  sortDirection === 'desc'
    ? (a, b) => (a[fieldForSortBy] < b[fieldForSortBy] ? -1 : 1)
    : (a, b) => (b[fieldForSortBy] < a[fieldForSortBy] ? -1 : 1);

export const getModalTitle = (modalType) => {
  if (modalType === 'editUser') return 'Редактирование пользователя';
  return 'Создание пользователя';
};

export const getOrderDirection = (orderBy, order) => {
  if (orderBy === 'roleName') return order;
  return 'asc';
};
