import axios from 'axios';
import { getFromLocalStorage, setToLocalStorage } from 'helpers/cashout';
import { API_URL } from 'constants/api';
import { setUserAuthorized } from 'actions/api';
import { redirectToPage } from 'actions/navigation';
import { setRequestError } from 'actions/snackbar';
import { startLoader, stopLoader } from 'actions/loader';

export const api = axios.create({
  baseURL: API_URL,
});

export const makeInterceptorRequestUseConfig = (dispatch, config) => {
  let token = getFromLocalStorage('token');

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    config.headers.Accept = 'application/json';
  }
  dispatch(startLoader());
  return config;
};

export const interceptorInstance = (store) => {
  const { dispatch } = store;

  api.interceptors.request.use((config) => {
    return makeInterceptorRequestUseConfig(dispatch, config);
  });

  api.interceptors.response.use(
    (res) => {
      dispatch(stopLoader());
      return res;
    },
    (err) => {
      dispatch(stopLoader());
      if (typeof err.response !== 'undefined') {
        const isUnAuthorized = err.response.data.message === 'Unauthorized';
        const message = isUnAuthorized
          ? 'Вы не авторизованы'
          : err.response.data.message;

        dispatch(setRequestError(true, message || err.response.data.messages));

        if (isUnAuthorized) {
          dispatch(redirectToPage(''));
          dispatch(setUserAuthorized(false));
          setToLocalStorage('token', '');
        }
      }
      return Promise.reject(err);
    }
  );
};
