import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Snackbar from '@material-ui/core/Snackbar';
import { CircularProgress } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

import Header from 'components/Header/header';
import Footer from 'components/Footer/footer';
import { getFromLocalStorage } from 'helpers/cashout';
import { redirectToPage } from 'actions/navigation';
import { setRequestError, setAlertMessage } from 'actions/snackbar';

import useStyles from './layoutStyle.js';

const Layout = (props) => {
  const classes = useStyles();
  const handleCloseSnackbar = () => {
    props.setAlertMessage('', false);
    props.setRequestError(false, '');
  };
  const path = useLocation().pathname;
  const token = getFromLocalStorage('token');

  useEffect(() => {
    if (!getFromLocalStorage('token') && path !== '/') {
      props.redirectToPage('');
    } // eslint-disable-next-line
  }, [token]);

  return (
    <div className={classes.layoutWrapper}>
      <Header />
      {props.isLoading && (
        <div className={classes.loaderWrapper} data-testid="loadingContainer">
          <CircularProgress className={classes.loaderContainer} />
        </div>
      )}
      <div
        className={classes.layoutChildrenWrapper}
        style={{ minHeight: 'calc(100vh - 250px)' }}
      >
        {props.children}
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={props.isRequestError || props.isAlertMessage}
        autoHideDuration={6000}
        className={
          (props.errorMessage && classes.snackbarErrorWrapper) ||
          (props.alertMessage && classes.snackbarAlertWrapper)
        }
        onClose={() => handleCloseSnackbar()}
        message={`${props.errorMessage || props.alertMessage}`}
        data-testid="snackBarWrapper"
      />

      <Snackbar />
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isRequestError: PropTypes.bool.isRequired,
  isAlertMessage: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  setRequestError: PropTypes.func.isRequired,
  redirectToPage: PropTypes.func.isRequired,
  children: PropTypes.object.isRequired,
  setAlertMessage: PropTypes.func.isRequired,
  alertMessage: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  isLoading: state.cashOutReducer.isLoading,
  isRequestError: state.cashOutReducer.isRequestError,
  errorMessage: state.cashOutReducer.errorMessage,
  alertMessage: state.cashOutReducer.alertMessage,
  isAlertMessage: state.cashOutReducer.isAlertMessage,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setRequestError,
      setAlertMessage,
      redirectToPage,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
