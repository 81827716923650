import { START_LOADER, STOP_LOADER } from 'actions/loader';
import { SET_ERROR_MESSAGE, SET_ALERT_MESSAGE } from 'actions/snackbar';
import {
  SET_REQUEST_LIST,
  SET_USER_LIST,
  SAVE_USER_EDITED_INFO,
  SET_SEARCH_REQUEST_DATA,
  SET_PROVIDER_LIST,
} from 'actions/users';
import { SET_USER_AUTHORIZED } from 'actions/api';

const getDefaultState = () => ({
  isLoading: false,
  userList: [],
  editedUserInfo: {},
  userIDX: null,
  isRequestError: false,
  errorMessage: '',
  isAuthorized: false,
  searchRequestsData: {},
  requestsList: [],
  isAlertMessage: false,
  alertMessage: '',
  providers: [],
});

const cashout = (state = getDefaultState(), action = {}) => {
  switch (action.type) {
    case SET_PROVIDER_LIST:
      return { ...state, providers: action.providers };
    case SET_ALERT_MESSAGE:
      return {
        ...state,
        alertMessage: action.alertMessage,
        isAlertMessage: action.isAlertMessage,
      };
    case SET_REQUEST_LIST:
      return { ...state, requestsList: action.requestsList };
    case SET_SEARCH_REQUEST_DATA:
      return { ...state, searchRequestsData: action.searchRequestsData };
    case START_LOADER:
      return { ...state, isLoading: true };
    case STOP_LOADER:
      return { ...state, isLoading: false };
    case SET_USER_AUTHORIZED:
      return { ...state, isAuthorized: action.isAuthorized };
    case SET_USER_LIST:
      return { ...state, userList: action.userList };
    case SET_ERROR_MESSAGE:
      return {
        ...state,
        isRequestError: action.isRequestError,
        errorMessage: action.errorMessage,
      };
    case SAVE_USER_EDITED_INFO:
      return {
        ...state,
        editedUserInfo: action.editedUserInfo,
        userIDX: action.userIDX,
      };
    default:
      return state;
  }
};

export default cashout;
