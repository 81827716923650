import { makeStyles, createStyles } from '@material-ui/core/styles';

const userEditStyle = makeStyles(() =>
  createStyles({
    editUserProfile: {
      display: 'flex',
      flexDirection: 'row',
    },
    formControlWrapper: {
      marginBottom: '7px',
      width: '100%',
    },
    gridWrapper: {
      width: '100%',
    },
    userEditTextField: {
      '& .MuiOutlinedInput-input': {
        padding: '12px 14px',
      },
    },
  })
);

export default userEditStyle;
