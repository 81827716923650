import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import { getFromLocalStorage } from 'helpers/cashout';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import LogoKassa24 from 'assets/img/kassa24.svg';
import Drawer from '@material-ui/core/Drawer';
import MenuIcon from '@material-ui/icons/Menu';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import GroupIcon from '@material-ui/icons/Group';
import { ADMIN_ROLE } from 'constants/main';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import useStyles from './headerStyle';
import history from 'appHistory';

const Header = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const classes = useStyles();
  const currentUser = getFromLocalStorage('currentUser');
  const userLogOut = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('currentUser');
    history.push('/');
  };

  const path = useLocation().pathname;

  const getClassNamesForLink = (href) => {
    if (path === href)
      return `${classes.headerLink} ${classes.activeHeaderLink}`;
    return classes.headerLink;
  };

  const getUserRoleName = () => {
    if (currentUser) return currentUser.roleName[0];
    return '';
  };

  const getUserLogin = () => {
    if (currentUser) return currentUser.login;
    return '';
  };

  return (
    <AppBar position="static">
      <Toolbar className={classes.headerWrapper}>
        {path !== '/' && (
          <Button
            onClick={() => setOpenDrawer(true)}
            className={classes.burgerButton}
            aria-label="burgerButton"
          >
            <MenuIcon className={classes.menuIcon} />
          </Button>
        )}
        <div className={classes.logoWrapper}>
          <Link to="/">
            <img
              src={LogoKassa24}
              alt="cashOut"
              className={classes.headerLogo}
            />
          </Link>
        </div>
        {path !== '/' ? (
          <>
            <div className={classes.menuWrapper}>
              <div className={classes.navBar}>
                {currentUser && currentUser.roleID === ADMIN_ROLE && (
                  <Link
                    to="/admin"
                    className={getClassNamesForLink('/admin')}
                    aria-label="Пользователи"
                  >
                    Пользователи
                  </Link>
                )}
                <Link
                  to="/requests"
                  className={getClassNamesForLink('/requests')}
                  aria-label="Заявки на выдачу"
                >
                  Заявки на выдачу
                </Link>
              </div>
              <div className={classes.chipWrapper}>
                <Chip
                  color="primary"
                  avatar={<Avatar>{getUserRoleName()}</Avatar>}
                  label={getUserLogin()}
                />
              </div>
              <Button
                color="inherit"
                onClick={() => userLogOut()}
                className={classes.headerLink}
                data-testid="logoutButton"
              >
                Выйти
              </Button>
            </div>
            <Drawer
              anchor={'left'}
              open={openDrawer}
              onClose={() => setOpenDrawer(false)}
              data-testid="menuDrawer"
            >
              <div className={classes.chipWrapper}>
                <Chip
                  avatar={<Avatar>{getUserRoleName()}</Avatar>}
                  label={getUserLogin()}
                  className={classes.chip}
                />
              </div>
              <List>
                <ListItem button>
                  <ListItemIcon>
                    <GroupIcon />
                  </ListItemIcon>
                  {currentUser && currentUser.roleID === ADMIN_ROLE && (
                    <Link
                      to="/admin"
                      className={getClassNamesForLink('/admin')}
                    >
                      Пользователи
                    </Link>
                  )}
                </ListItem>
                <ListItem button>
                  <ListItemIcon>
                    <FindInPageIcon />
                  </ListItemIcon>

                  <Link
                    to="/requests"
                    className={getClassNamesForLink('/requests')}
                  >
                    Заявки на выдачу
                  </Link>
                </ListItem>
              </List>
              <Divider />
              <List>
                <ListItem
                  button
                  onClick={() => userLogOut()}
                  data-testid="logoutButtonMobile"
                >
                  <ListItemIcon>
                    <ExitToAppIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={'Выйти'}
                    className={classes.headerLink}
                  />
                  {/* <Button
                    color="inherit"


                  >
                    Выйти
                  </Button> */}
                </ListItem>
              </List>
            </Drawer>
          </>
        ) : (
          <div />
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
