import React, { useEffect, useState, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import {
  saveEditedInfoInStore,
  hadleUserListRequest,
  changeStatus,
  saveUserEditData,
} from 'actions/users';
import {
  getUserList,
  editUserProfile,
  createUserProfile,
  deactivateUser,
  activateUser,
} from 'actions/api';
import { userDetailsTranslate } from 'constants/main';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import BlockIcon from '@material-ui/icons/Block';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ListAltIcon from '@material-ui/icons/ListAlt';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import DialogTitle from '@material-ui/core/DialogTitle';
import UserEdit from 'components/UserEdit/userEdit.js';
import useStyles from './usersStyle.js';
import {
  viewUserProfile,
  getUserRoleValue,
  getSorting,
  getModalTitle,
  getOrderDirection,
  getCurrentUserBool,
} from 'helpers/users';

const StyledTableCell = withStyles((theme) => {
  return {
    head: {
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.common.black,
    },
    body: {
      fontSize: 14,
    },
  };
})(TableCell);

const Main = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [editedUser, setEditedUser] = useState();
  const [modalType, setModalType] = useState(null);
  const [openHistoryModal, setOpenHistoryModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [validationError, setValidationError] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [selectedRole, setSelectedRole] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const classes = useStyles();
  const modalRef = useRef();

  useEffect(() => {
    props.getUserList(hadleUserListRequest);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCloseModal = () => {
    setAnchorEl(null);
    setEditedUser({});
    setOpen(false);
  };

  const viewUserProfileHandler = (user) => {
    viewUserProfile(user, setCurrentUser, setModalType, setOpen);
  };

  const handleChangeUserEdit = (e) => {
    let tempUserEdit = {
      ...editedUser,
      [e.target.name]: e.target.value,
    };

    setEditedUser(tempUserEdit);
  };

  const changeStatusHandler = (user) => {
    props.changeStatus(user);
  };

  const saveUserEditDataHandler = () => {
    props.saveUserEditData({
      currentUser,
      selectedRole,
      editedUser,
      modalType,
      setValidationError,
      handleCloseModal,
    });
  };

  const displayStatusHistory = (user) => {
    setCurrentUser(user);
    setOpenHistoryModal(true);
  };

  const renderModal = () => {
    let modalData = <div />;
    let modalTitle = '';
    const excludedFields = [
      'id',
      'status',
      'login',
      'roleName',
      'roleID',
      'statusHistory',
      'providerName',
    ];

    if (modalType === 'viewProfile') {
      modalTitle = 'Информация о пользователе';
      modalData = (
        <Card className={classes.root}>
          <CardHeader
            avatar={
              <Avatar aria-label="recipe" className={classes.avatar}>
                {getUserRoleValue(currentUser)}
              </Avatar>
            }
            title={`${currentUser.login} (ID: ${currentUser.id})`}
            subheader={currentUser.roleName}
          />
          <CardContent>
            <Table>
              <TableBody>
                {Object.keys(currentUser).map((item, idx) => {
                  if (!excludedFields.includes(item)) {
                    if (item === 'smsSending' || item === 'backUrlSending') {
                      return (
                        <TableRow key={idx}>
                          <TableCell>{userDetailsTranslate[item]}</TableCell>
                          <TableCell align="left">
                            {getCurrentUserBool(currentUser, item)}
                          </TableCell>
                        </TableRow>
                      );
                    }

                    return (
                      <TableRow key={idx}>
                        <TableCell>{userDetailsTranslate[item]}</TableCell>
                        <TableCell align="left">{currentUser[item]}</TableCell>
                      </TableRow>
                    );
                  }
                  return null;
                })}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      );
    } else if (modalType === 'editUser' || modalType === 'createUser') {
      modalTitle = getModalTitle(modalType);
      modalData = (
        <div className={classes.modalChildWrapper}>
          <UserEdit
            currentUser={currentUser}
            refprops={modalRef}
            handleChangeUserEdit={handleChangeUserEdit}
            errorMessages={validationError}
            selectedRole={selectedRole}
            handleSelect={handleSelect}
            editedUser={editedUser}
            createUser={modalType === 'createUser'}
            {...props}
          />
        </div>
      );
    }

    return (
      <Dialog
        maxWidth="md"
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="form-dialog-title"
        ref={modalRef}
      >
        <DialogTitle id="form-dialog-title">{modalTitle}</DialogTitle>
        <DialogContent>{modalData}</DialogContent>
        <DialogActions>
          {modalType === 'editUser' || modalType === 'createUser' ? (
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={saveUserEditDataHandler}
              className={classes.button}
              startIcon={<SaveIcon />}
            >
              Сохранить
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={handleCloseModal}
              className={classes.button}
            >
              Ок
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  };

  const userEditProfile = (user) => {
    setValidationError([]);
    setSelectedRole(user.roleID);
    setModalType('editUser');
    setCurrentUser(user);
    setOpen(true);
  };

  const handleSelect = (val, name) => {
    let editedUserClone = {};
    editedUserClone = { ...editedUser, [name]: val };
    setEditedUser(editedUserClone);
    if (name === 'roleName') {
      setSelectedRole(val);
    }
  };

  const handleMenuItem = (item) => {
    setCurrentUser({});
    setValidationError([]);
    setOpen(true);
    setModalType('createUser');
    setSelectedRole(item.target.value);
  };

  const createSortHandler = (key) => {
    setOrderBy(key);
    setOrder(order === 'asc' ? 'desc' : 'asc');
  };

  return (
    <>
      <div className={classes.tableWrapper}>
        <div className={classes.controlBlock}>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
            className={classes.menuButton}
          >
            Создать пользователя
          </Button>
        </div>

        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl) && !open}
          onClose={handleCloseMenu}
          data-testid="simpleMenu"
        >
          <MenuItem value={1} onClick={handleMenuItem}>
            Администратор
          </MenuItem>
          <MenuItem value={2} onClick={handleMenuItem}>
            Провайдер
          </MenuItem>
        </Menu>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow className={classes.tableTD}>
                <StyledTableCell className={classes.tableTD}>
                  <TableSortLabel
                    active={true}
                    direction={getOrderDirection('login', order)}
                    onClick={() => createSortHandler('login')}
                    aria-label="sortLogin"
                  >
                    Логин
                  </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell className={classes.tableTD} align="center">
                  <TableSortLabel
                    active={true}
                    direction={getOrderDirection('roleName', order)}
                    onClick={() => createSortHandler('roleName')}
                    aria-label="sortRole"
                  >
                    Роль
                  </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell className={classes.tableTD} align="center">
                  <TableSortLabel
                    active={true}
                    direction={getOrderDirection('status', order)}
                    onClick={() => createSortHandler('status')}
                    aria-label="roleStatus"
                  >
                    Статус
                  </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell className={classes.tableTD} align="center">
                  <TableSortLabel
                    active={true}
                    direction={getOrderDirection('providerClientName', order)}
                    onClick={() => createSortHandler('providerClientName')}
                    aria-label="sortNameService"
                  >
                    Наименование сервиса
                  </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell className={classes.tableTD} align="center">
                  <TableSortLabel
                    active={true}
                    direction={getOrderDirection('createdAt', order)}
                    onClick={() => createSortHandler('createdAt')}
                    aria-label="dateCreate"
                  >
                    Дата создания
                  </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell className={classes.tableTD} align="center">
                  Операции
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody aria-label="tableBody">
              {props.userList
                .sort(getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

                .map((row, idx) => (
                  <TableRow key={idx}>
                    <TableCell className={classes.tableTD}>
                      {row.login}
                    </TableCell>
                    <TableCell className={classes.tableTD} align="center">
                      {row.roleName}
                    </TableCell>
                    <TableCell className={classes.tableTD} align="center">
                      <span
                        className={`${classes.statusBadge} ${
                          row.status === 1
                            ? classes.statusBadgeSuccess
                            : classes.statusBadgeFailed
                        }`}
                      >
                        {row.statusName}
                      </span>
                    </TableCell>
                    <TableCell className={classes.tableTD} align="center">
                      {row.providerClientName}
                    </TableCell>
                    <TableCell className={classes.tableTD} align="center">
                      {row.createdAt}
                    </TableCell>
                    <TableCell className={classes.tableTD} align="left">
                      <Tooltip title="Просмотреть профиль">
                        <Button
                          size="small"
                          className={classes.operationButton}
                          onClick={() => viewUserProfileHandler(row)}
                          data-testid={`viewUser${idx}`}
                        >
                          <VisibilityIcon />
                        </Button>
                      </Tooltip>

                      <Tooltip title="Редактировать профиль">
                        <Button
                          size="small"
                          className={classes.operationButton}
                          onClick={() => userEditProfile(row)}
                          data-testid={`editUser${idx}`}
                        >
                          <EditIcon />
                        </Button>
                      </Tooltip>
                      <Tooltip
                        title={
                          row.status === 1 ? 'Заблокировать' : 'Разблокировать'
                        }
                      >
                        <Button
                          size="small"
                          className={classes.operationButton}
                          onClick={() => changeStatusHandler(row)}
                          data-testid={`blockUser${idx}`}
                        >
                          {row.status === 1 ? <BlockIcon /> : <LockOpenIcon />}
                        </Button>
                      </Tooltip>
                      {row['statusHistory'] && (
                        <Tooltip title="Просмотреть историю">
                          <Button
                            size="small"
                            onClick={() => displayStatusHistory(row)}
                            className={classes.operationButton}
                            data-testid={`showHistory${idx}`}
                          >
                            <ListAltIcon />
                          </Button>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 15, 20]}
          component="div"
          count={props.userList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={'Количество'}
          aria-label="tabelPaging"
        />
        {renderModal()}
        <Dialog
          maxWidth="md"
          open={openHistoryModal}
          onClick={() => setOpenHistoryModal(false)}
          aria-labelledby="form-dialog-title"
          ref={modalRef}
          data-testid="historyModal"
        >
          <DialogTitle id="form-dialog-title">История</DialogTitle>
          <DialogContent>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell className={classes.tableTD} align="center">
                      Кем изменен
                    </StyledTableCell>
                    <StyledTableCell className={classes.tableTD} align="center">
                      Статус
                    </StyledTableCell>
                    <StyledTableCell className={classes.tableTD} align="center">
                      Дата
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody aria-label="tableBodyHistory">
                  {currentUser['statusHistory'] &&
                    currentUser['statusHistory'].map((historyItem, ids) => {
                      return (
                        <TableRow key={ids}>
                          <TableCell>
                            {historyItem['modifiedByLogin']}
                          </TableCell>
                          <TableCell align="left">
                            {historyItem['statusName']}
                          </TableCell>
                          <TableCell align="left">
                            {historyItem['modifiedAt']}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => setOpenHistoryModal(false)}
              className={classes.button}
            >
              Ок
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

Main.propTypes = {
  userList: PropTypes.array.isRequired,
  getUserList: PropTypes.func.isRequired,
  editUserProfile: PropTypes.func.isRequired,
  saveEditedInfoInStore: PropTypes.func.isRequired,
  createUserProfile: PropTypes.func.isRequired,
  deactivateUser: PropTypes.func.isRequired,
  activateUser: PropTypes.func.isRequired,
  changeStatus: PropTypes.func.isRequired,
  saveUserEditData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  userList: state.cashOutReducer.userList,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUserList,
      editUserProfile,
      saveEditedInfoInStore,
      createUserProfile,
      deactivateUser,
      activateUser,
      changeStatus,
      saveUserEditData,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Main);
