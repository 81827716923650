import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import ReplayIcon from '@material-ui/icons/Replay';
import CancelIcon from '@material-ui/icons/Cancel';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { formatAmount, getDateFromTimestamp } from 'helpers/cashout';
import Tooltip from '@material-ui/core/Tooltip';
import MessageIcon from '@material-ui/icons/Message';
import useStyles from './requestsResultStyle';

const StyledTableCell = withStyles((theme) => {
  return {
    head: {
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.common.black,
    },
    body: {
      fontSize: 14,
    },
  };
})(TableCell);

const RequestsResult = ({
  requestsList,
  resendCallbackUrl,
  requestCancel,
  resendSmsCallback,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const classes = useStyles();

  const handleCancelRequest = (id) => {
    requestCancel(id);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleResendCallback = (id) => {
    resendCallbackUrl(id);
  };

  const getSorting = (sortDirection, fieldForSortBy) =>
    sortDirection === 'desc'
      ? (a, b) => (a[fieldForSortBy] < b[fieldForSortBy] ? -1 : 1)
      : (a, b) => (b[fieldForSortBy] < a[fieldForSortBy] ? -1 : 1);

  const createSortHandler = (key) => {
    setOrderBy(key);
    setOrder(order === 'asc' ? 'desc' : 'asc');
  };

  const handleSmsReSend = (key) => {
    resendSmsCallback(key);
  };

  const getSortDirection = (orderField) => {
    if (orderBy === orderField) return order;
    return 'asc';
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow className={classes.rowWrapper}>
              <StyledTableCell className={classes.tableTD}>№</StyledTableCell>
              <StyledTableCell className={classes.tableTD} align="center">
                <TableSortLabel
                  active={true}
                  direction={orderBy === 'providerRequestID' ? order : 'asc'}
                  onClick={() => createSortHandler('providerRequestID')}
                  aria-label="providerRequestID"
                >
                  Номер заявки
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell className={classes.tableTD} align="center">
                Номер телефона
              </StyledTableCell>
              <StyledTableCell className={classes.tableTD} align="center">
                Статус
              </StyledTableCell>
              <StyledTableCell className={classes.tableTD} align="center">
                Дата истечения
              </StyledTableCell>
              <StyledTableCell className={classes.tableTD} align="center">
                Дата создания
              </StyledTableCell>
              <StyledTableCell className={classes.tableTD} align="center">
                Дата выдачи
              </StyledTableCell>
              <StyledTableCell className={classes.tableTD} align="center">
                Провайдер
              </StyledTableCell>
              <StyledTableCell className={classes.tableTD} align="center">
                <TableSortLabel
                  active={true}
                  direction={getSortDirection('providerClientName')}
                  onClick={() => createSortHandler('providerClientName')}
                  aria-label="providerClientName"
                >
                  Наименование сервиса
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell className={classes.tableTD} align="center">
                <TableSortLabel
                  active={true}
                  direction={getSortDirection('amountRequest')}
                  onClick={() => createSortHandler('amountRequest')}
                  aria-label="amountRequest"
                >
                  Сумма к выдаче
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell className={classes.tableTD} align="center">
                <TableSortLabel
                  active={true}
                  direction={getSortDirection('amountOut')}
                  onClick={() => createSortHandler('amountOut')}
                  aria-label="amountOut"
                >
                  Выданная сумма
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell className={classes.tableTD} align="center">
                <TableSortLabel
                  active={true}
                  direction={getSortDirection('terminalID')}
                  onClick={() => createSortHandler('terminalID')}
                  aria-label="terminalID"
                >
                  Терминал
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell className={classes.tableTD} align="center">
                Операции
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody aria-label="tableBody">
            {requestsList
              .sort(getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, idx) => {
                const {
                  phoneNumber,
                  providerID,
                  providerName,
                  terminalID,
                  providerRequestID,
                  amountRequest,
                  providerClientName,
                  amountOut,
                  statusName,
                  expiredAt,
                  createdAt,
                  issuedAt,
                } = row;

                return (
                  <TableRow key={idx} className={classes.rowWrapper}>
                    <TableCell className={classes.tableTD}>{idx + 1}</TableCell>
                    <TableCell className={classes.tableTD} align="center">
                      {providerRequestID}
                    </TableCell>
                    <TableCell className={classes.tableTD} align="center">
                      {phoneNumber}
                    </TableCell>
                    <TableCell className={classes.tableTD} align="center">
                      {statusName}
                    </TableCell>
                    <TableCell className={classes.tableTD} align="center">
                      {getDateFromTimestamp(expiredAt)}
                    </TableCell>
                    <TableCell className={classes.tableTD} align="center">
                      {getDateFromTimestamp(createdAt)}
                    </TableCell>
                    <TableCell className={classes.tableTD} align="center">
                      {issuedAt ? getDateFromTimestamp(issuedAt) : '-'}
                    </TableCell>
                    <TableCell className={classes.tableTD} align="center">
                      {providerName} {'(ID:' + providerID + ')'}
                    </TableCell>
                    <TableCell className={classes.tableTD} align="center">
                      <span className={classes.statusBadge}>
                        {providerClientName}
                      </span>
                    </TableCell>
                    <TableCell className={classes.tableTD} align="center">
                      {formatAmount(amountRequest)} ₸
                    </TableCell>
                    <TableCell className={classes.tableTD} align="center">
                      {formatAmount(amountOut)} ₸
                    </TableCell>
                    <TableCell className={classes.tableTD} align="center">
                      {terminalID}
                    </TableCell>
                    <TableCell className={classes.tableTD} align="center">
                      {row.hasOwnProperty('resendSMSUrl') && (
                        <Tooltip title="Переотправить СМС с кодом подтверждения">
                          <MessageIcon
                            onClick={() =>
                              handleSmsReSend(row.cashOutRequestID)
                            }
                            className={classes.iconWrapper}
                            aria-label="resendSms"
                          />
                        </Tooltip>
                      )}
                      {row.hasOwnProperty('resendCallbackUrl') && (
                        <Tooltip title="Переотправить данные на Back URL">
                          <ReplayIcon
                            onClick={() =>
                              handleResendCallback(row.cashOutRequestID)
                            }
                            className={classes.iconWrapper}
                            aria-label="resendData"
                          />
                        </Tooltip>
                      )}
                      {row.hasOwnProperty('cancelUrl') && (
                        <Tooltip title="Отменить заявку">
                          <CancelIcon
                            className={classes.iconWrapper}
                            onClick={() =>
                              handleCancelRequest(row.cashOutRequestID)
                            }
                            aria-label="cancelUrl"
                          />
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 15, 20]}
          component="div"
          count={requestsList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={'Количество'}
          aria-label="tabelPaging"
        />
      </TableContainer>
    </div>
  );
};

RequestsResult.propTypes = {
  requestsList: PropTypes.array.isRequired,
  resendCallbackUrl: PropTypes.func.isRequired,
  requestCancel: PropTypes.func.isRequired,
  resendSmsCallback: PropTypes.func.isRequired,
};

export default RequestsResult;
