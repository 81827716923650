import {
  deactivateUser,
  activateUser,
  editUserProfile,
  createUserProfile,
} from 'actions/api';
import { UserTypes, AdminFields, ProviderFields } from 'constants/main';

export const SET_USER_LIST = 'SET_USER_LIST';
export const SAVE_USER_EDITED_INFO = 'SAVE_USER_EDITED_INFO';
export const SET_SEARCH_REQUEST_DATA = 'SET_SEARCH_REQUEST_DATA';
export const SET_REQUEST_LIST = 'SET_REQUEST_LIST';
export const SET_PROVIDER_LIST = 'SET_PROVIDER_LIST';

export const setProviderList = (data) => (dispatch) => {
  dispatch({ type: SET_PROVIDER_LIST, providers: data });
};

export const setRequestList = (data) => (dispatch) => {
  dispatch({ type: SET_REQUEST_LIST, requestsList: data });
};
export const saveSearchRequestData = (data) => (dispatch) => {
  dispatch({ type: SET_SEARCH_REQUEST_DATA, searchRequestsData: data });
};
export const saveEditedInfoInStore = (info, idx) => (dispatch) => {
  dispatch({ type: SAVE_USER_EDITED_INFO, editedUserInfo: info, userIDX: idx });
};

export const hadleUserListRequest = (list) => (dispatch) => {
  return dispatch({ type: SET_USER_LIST, userList: list });
};

export const changeStatus = (user) => (dispatch) => {
  if (user.status === 2) {
    dispatch(activateUser(user.id));
  }
  if (user.status === 1) {
    dispatch(deactivateUser(user.id));
  }
};

export const saveUserEditData = (props) => (dispatch) => {
  const { currentUser, selectedRole, editedUser, modalType } = props;
  let tempdata = {
    ...currentUser,
    roleID: selectedRole,
    roleName: UserTypes[selectedRole],
    multipleOf: null,
  };

  let userIDX = null;

  if (editedUser) {
    Object.keys(editedUser).forEach((objectKey, idx) => {
      if (editedUser[objectKey] !== currentUser[objectKey]) {
        tempdata = {
          ...tempdata,
          [objectKey]: editedUser[objectKey],
        };

        userIDX = idx;
      }
    });
  }

  if (modalType === 'editUser') {
    tempdata['password'] = null;
  }

  if (selectedRole === 1) {
    dispatch(checkSelectedRoleOne(props, tempdata, userIDX));
  } else if (selectedRole === 2) {
    dispatch(checkSelectedRoleTwo(props, tempdata, userIDX));
  }
};

export const checkSelectedRoleOne =
  (props, tempdata, userIDX) => (dispatch) => {
    const { setValidationError } = props;
    let errorsArr = [];
    AdminFields.forEach((item) => {
      if (!(item in tempdata) || tempdata[item] === '') {
        errorsArr.push(item);
      }
    });
    setValidationError(errorsArr);

    if (errorsArr.length === 0) {
      dispatch(saveEditedInfoInStore(tempdata, userIDX));
      dispatch(checkModalType(props));
    }
  };

export const checkSelectedRoleTwo =
  (props, tempdata, userIDX) => (dispatch) => {
    const { setValidationError } = props;
    let errorsArr = [];
    ProviderFields.forEach((item) => {
      if (item !== 'multipleOf' && item !== 'providerName') {
        if (!(item in tempdata) || tempdata[item] === '') {
          errorsArr.push(item);
        }
      }
    });

    setValidationError(errorsArr);
    if (errorsArr.length === 0) {
      if (tempdata['multipleOf'] === '') {
        delete tempdata['multipleOf'];
      }
      saveEditedInfoInStore(tempdata, userIDX);
      dispatch(checkModalType(props));
    }
  };

export const checkModalType = (props) => (dispatch) => {
  const { setValidationError, modalType, handleCloseModal, currentUser } =
    props;
  if (modalType === 'createUser') dispatch(createUserProfile());
  else dispatch(editUserProfile(currentUser.id));
  setValidationError([]);
  handleCloseModal();
};
