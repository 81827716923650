import { makeStyles, createStyles } from '@material-ui/core/styles';

const requestsResultStyle = makeStyles((theme) =>
  createStyles({
    iconWrapper: {
      cursor: 'pointer',
    },
    rowWrapper: {
      textAlign: 'center',
    },
    statusBadge: {
      padding: '3px 5px',
    },
    tableTD: {
      '&.MuiTableCell-root': {
        padding: '8px 10px',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '0 7px',
      },
    },
  })
);

export default requestsResultStyle;
