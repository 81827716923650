import { makeStyles, createStyles } from '@material-ui/core/styles';

const usersStyle = makeStyles((theme) =>
  createStyles({
    tableWrapper: {
      width: '90%',
      margin: '50px  auto 0 auto ',
      outline: 'none',
      padding: '10px',
      [theme.breakpoints.down('xs')]: {
        width: '95%',
      },
    },
    operationButton: {
      '&.MuiButton-root': {
        minWidth: 'auto',
      },
    },

    statusBadge: {
      padding: '3px 11px',
      borderRadius: '12px',
      marginLeft: '5px',
    },

    statusBadgeSuccess: {
      background: '#049b0a',
      color: '#ffffff',
    },
    statusBadgeFailed: {
      background: '#8d8d8d',
      color: '#ffffff',
    },
    tableTD: {
      '&.MuiTableCell-root': {
        padding: '8px 10px',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '0 7px',
      },
    },
    modalWrapper: {
      justifyContent: 'center',
      display: 'flex',
      alignItems: 'flex-start',
    },
    modalChildWrapper: {
      outline: 'none',
    },
    menuButton: {
      background: 'linear-gradient(315deg, #ffd200 18.29%, #FFF229 83.33%)',
      color: '#231f20 !important',
      boxShadow: '0 4px 12px rgba(204, 168, 0, 0.32) !important',
      display: 'flex',
    },
    editUserProfile: {
      padding: '10px',
      background: '#ffffff',
      display: 'inline-flex',
      flexFlow: 'column',
      // width: '51%',
      margin: '60px auto 0 auto',
      outline: 'none',
      alignItems: 'center',
      borderRadius: '5px',
    },
    userEditTextField: {
      marginBottom: '10px',
      width: '300px',
      '& input': {
        // width: '50%',
      },
      '& .MuiOutlinedInput-input': {
        padding: '12px 14px',
      },
    },
    controlBlock: {
      margin: '0 20px 20px 0',
    },
  })
);

export default usersStyle;
