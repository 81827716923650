export const ProviderFields = [
  'login',
  'password',
  'roleName',
  'providerID',
  'providerName',
  'terminalID',
  'cashOutRequestLifetime',
  'providerClientName',
  'smsSending',
  'backUrlSending',
  'multipleOf',
];

export const ADMIN_ROLE = 1;
export const PROVIDER_ROLE = 2;
export const UserTypes = {
  1: 'Администратор',
  2: 'Провайдер',
};

export const immutableProperties = [
  'createdAt',
  'id',
  'roleID',
  'providerName',
];

export const userDetailsTranslate = {
  id: 'id',
  login: 'Логин',
  roleID: '	Идентификатор роли',
  password: 'Пароль',
  roleName: 'Роль',
  providerID: 'Номер провайдера',
  providerName: 'Провайдер',
  terminalID: 'Номер терминала',
  cashOutRequestLifetime: 'Срок жизни заявки',
  smsSending: 'Отправка смс-сообщений по заявке',
  backUrlSending: 'Отправка данных на Back URL',
  createdAt: 'Дата создания',
  statusName: 'Статус',
  providerClientName: 'Наименование сервиса',
  multipleOf: 'Кратность суммы',
};

export const AdminFields = [
  'login',
  'roleName',
  'password',
  // 'providerClientName',
];

export const PUBLIC_JWT_KEY = `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEArEzcXh1YMQtQZCvFUljA
6HVbxTlOimj7EkILuaXyudLDdDzCqWl5rVf3Y4/7BLAeq3WaxxvFYUEmKOZHxoQp
gsdGkmOZVtjMdLtGxXGbYJZq17aUxvPXM0BWGtwSbessrWAqxKBPXesGyquC+lkb
IZ7V4vEKGYtSa31WiPMMtGAsyf5Q6rWf2YX7Wou3iYTsiBRBJyH6wDjHaGqGhEBv
6SBoi8yYTYLpYATIKnG64FA4lRWeLUxwCzKRGBdmGSkJKlyE8C6htQjT/r2wqSyV
PnH1GdFTIGR6qjl/3uhnnFj3oTAzDOPMprqX0nB51jSnsqKSmVCAhvEZ/DJy+TYG
8t3HiuIfwZRyv7OW6GRPgJ9e43CcRx7/YydTWkSwpPbZSkhU7diUYRfm9PcCVpf3
K4IvjG8w8+DOuNfYs/m5JK90dL4AM7u4KG3bbTOhkQIJTY31SZgAwoU06zUeEDbR
pS3StixUq2VpOcMQmpG0FqHJXtxySvTzDtbthIGB3xvW0btZpiUb45unwvt5F+B/
ytRFI4mbuyheCN0WkX4r3NT7RmqAfgwJc5FUNSuXTBPokx1Gwq5+jkZyYTcnrOiO
xuhxBWusxvdAc7IEJXClgMXSekUDm5auDAeL/R5ATQRvrEDEvQ5yX8Pl9cAFUkl4
/Y1y/hFQN/8mC5OvTvP87bkCAwEAAQ==
-----END PUBLIC KEY-----`;
