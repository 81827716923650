import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { getFromLocalStorage } from 'helpers/cashout';
import {
  resendCallbackUrl,
  requestCancel,
  getProviders,
  resendSmsCallback,
} from 'actions/api';
import PropTypes from 'prop-types';
import SearchForm from 'components/searchForm/searchForm';
import { ADMIN_ROLE } from 'constants/main';
import RequestsResult from 'components/requestsResult/requestsResult';

const useStyles = makeStyles((theme) => ({
  requestsWrapper: {
    width: '90%',
    margin: '50px  auto 0 auto ',
    outline: 'none',
    padding: '10px',
    [theme.breakpoints.down('xs')]: {
      width: '95%',
    },
  },
}));

const Requests = (props) => {
  const classes = useStyles();
  const currentUser = getFromLocalStorage('currentUser');

  const { getProviders: getProvidersList } = props;

  useEffect(() => {
    if (currentUser && currentUser.roleID === ADMIN_ROLE) {
      getProvidersList();
    }
  }, [currentUser, getProvidersList]);

  return (
    <div className={classes.requestsWrapper}>
      <SearchForm isAdmin={currentUser && currentUser.roleID === ADMIN_ROLE} />
      <RequestsResult
        requestsList={props.requestsList}
        resendCallbackUrl={props.resendCallbackUrl}
        requestCancel={props.requestCancel}
        resendSmsCallback={props.resendSmsCallback}
      />
    </div>
  );
};

Requests.propTypes = {
  requestsList: PropTypes.array.isRequired,
  resendCallbackUrl: PropTypes.func.isRequired,
  requestCancel: PropTypes.func.isRequired,
  getProviders: PropTypes.func.isRequired,
  resendSmsCallback: PropTypes.func,
};

const mapStateToProps = (state) => ({
  requestsList: state.cashOutReducer.requestsList,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resendCallbackUrl,
      requestCancel,
      getProviders,
      resendSmsCallback,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Requests);
