import React from 'react';
import * as Sentry from '@sentry/react';
import {
  Route,
  Routes as RouterCustom,
  unstable_HistoryRouter as HistoryRouter,
} from 'react-router-dom';

import history from 'appHistory';
import Main from 'views/Users/users';
import ErrorPage from 'views/ErrorPage';
import Layout from 'components/layout/layout';
import Requests from 'views/Requests/requests';
import SignIn from 'components/SignIn/sign-in';

const getRouterElement = (Component) => (
  <Layout>
    <Component />
  </Layout>
);

const Routes = () => {
  return (
    <Sentry.ErrorBoundary fallback={ErrorPage}>
      <HistoryRouter history={history}>
        <RouterCustom>
          <Route exact path="/" element={getRouterElement(SignIn)} />
          <Route path="/admin" element={getRouterElement(Main)} />
          <Route path="/requests" element={getRouterElement(Requests)} />
          <Route path="*" exact={true} element={getRouterElement(SignIn)} />
        </RouterCustom>
      </HistoryRouter>
    </Sentry.ErrorBoundary>
  );
};

export default Routes;
