export const setToLocalStorage = (name, data) => {
  window.localStorage.setItem(name, JSON.stringify(data));
};

export const getFromLocalStorage = (name) => {
  if (window.localStorage.getItem(name) !== null) {
    return JSON.parse(window.localStorage.getItem(name));
  }

  return null;
};

export const formatDate = (date) => {
  let d = new Date(date);

  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  let year = d.getFullYear();
  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return `${[year, month, day].join('-')} ${['00', '00', '00'].join(':')}`;
};

export const formatAmount = (amount) => Number(amount).toLocaleString('ru-RU');

export const getDateFromTimestamp = (timestamp) => {
  const d = new Date(timestamp * 1000).toLocaleString('ru-RU',{timeZone:'Asia/Almaty'});
  const dateArr=d.split(', ');
  const date=dateArr[0]
  const time=dateArr[1]

  return `${time} ${date}`;
};