import { combineReducers } from 'redux';
import auth from './auth.js';
import cashOutReducer from './cashout.js';

const rootReducer = combineReducers({
  auth,
  cashOutReducer,
});

export default rootReducer;
