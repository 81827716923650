import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { saveEditedInfoInStore, saveSearchRequestData } from 'actions/users';
import PropTypes from 'prop-types';
import { formatDate } from 'helpers/cashout';
import Button from '@material-ui/core/Button';
import {
  editUserProfile,
  createUserProfile,
  getRequestList,
} from 'actions/api';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { FormControl } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import useStyles from './searchFormStyle.js';
import 'flatpickr/dist/flatpickr.css';
import { Russian } from 'flatpickr/dist/l10n/ru.js';
import Flatpickr from 'react-flatpickr';

const SearchForm = (props) => {
  const classes = useStyles();
  const today = new Date();
  const tomorrow = new Date(today);
  const [date, setDate] = useState({
    from: new Date(),
    to: tomorrow.setDate(tomorrow.getDate() + 1),
  });
  const [providerID, setProviderID] = useState(null);
  const [inputValues, setInputValues] = useState({});
  const [errorSelect, setErrorSelect] = useState(false);
  const [phoneError, setPhoneError] = useState();

  const handleCalendarChange = (val, name) => {
    let tempDate = { ...date };
    tempDate = { ...tempDate, [name]: val[0] };
    setDate(tempDate);
  };

  const handleInputChange = (e) => {
    let tempInputVal = { ...inputValues };
    tempInputVal = { ...tempInputVal, [e.target.name]: e.target.value };

    setInputValues(tempInputVal);

    if (e.target.name === 'phoneNumber') {
      setPhoneError(false);
    }
  };

  const handleSelect = (value) => {
    setProviderID(value);
  };

  const hasPhoneNumberError = () => {
    let hasError = false;

    if (inputValues.phoneNumber && inputValues.phoneNumber.length <= 9) {
      hasError = true;
    }

    setPhoneError(hasError);
    return hasError;
  };

  const hasProviderError = () => {
    let hasError = false;

    if (props.isAdmin && !providerID) {
      hasError = true;
    }

    setErrorSelect(hasError);
    return hasError;
  };

  const handleClickRequest = () => {
    if (!hasPhoneNumberError() && !hasProviderError()) {
      let preparedData = {
        from: formatDate(date.from),
        to: formatDate(date.to),
        ...inputValues,
      };

      Object.keys(preparedData).forEach((item) => {
        if (
          !preparedData[item] ||
          (typeof preparedData[item] === 'string' && !preparedData[item].trim())
        ) {
          delete preparedData[item];
        }
      });

      if (props.isAdmin) {
        preparedData.providerID = providerID;
      }

      props.saveSearchRequestData(preparedData);
      props.getRequestList();
    }
  };

  return (
    <div className={classes.searchBlockCont}>
      <div className={classes.searchBlock}>
        <Flatpickr
          aria-label="dateFrom"
          id="date"
          placeholder="Выберите период"
          options={{
            dateFormat: 'd-m-Y',
            locale: Russian,
          }}
          className={classes.calendarWrapper}
          onChange={(val) => handleCalendarChange(val, 'from')}
          value={date.from}
        />

        <Flatpickr
          aria-label="dateTo"
          id="date"
          placeholder="Выберите период"
          options={{
            dateFormat: 'd-m-Y',
            locale: Russian,
          }}
          className={classes.calendarWrapper}
          onChange={(val) => handleCalendarChange(val, 'to')}
          value={date.to}
        />

        <TextField
          id="phone"
          inputProps={{ maxLength: 10 }}
          label="Номер телефона"
          variant="outlined"
          name="phoneNumber"
          className={classes.searchItem}
          onChange={(e) => handleInputChange(e)}
          value={inputValues.phoneNumber || ''}
          helperText={
            phoneError && 'Номер некорректен. Формат номера: 7771234567'
          }
          error={phoneError}
        />

        <TextField
          id="orderid"
          label="Номер заявки"
          name="providerRequestID"
          className={classes.searchItem}
          onChange={(e) => handleInputChange(e)}
          variant="outlined"
        />

        {props.isAdmin && (
          <FormControl
            className={classes.searchItem}
            variant="outlined"
            error={errorSelect}
          >
            <InputLabel id="select-outlined-label-provider">
              Провайдер
            </InputLabel>
            <Select
              labelId="select-outlined-label-provider"
              required
              defaultValue={''}
              onChange={(e) => handleSelect(e.target.value)}
              label={'Провайдер'}
              className={classes.formControl}
              // className={classes.userEditTextField}
            >
              {props.providers.map((provider, idx) => {
                return (
                  <MenuItem key={idx} value={provider.id}>
                    {provider.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}
      </div>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        className={classes.searchSubmitBtn}
        onClick={() => handleClickRequest()}
      >
        Искать
      </Button>
    </div>
  );
};

SearchForm.propTypes = {
  providers: PropTypes.array.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  saveSearchRequestData: PropTypes.func.isRequired,
  getRequestList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  providers: state.cashOutReducer.providers,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      editUserProfile,
      saveEditedInfoInStore,
      createUserProfile,
      getRequestList,
      saveSearchRequestData,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SearchForm);
