export const START_LOADER = 'START_LOADER';
export const STOP_LOADER = 'STOP_LOADER';

export const startLoader = () => (dispatch) => {
  dispatch({ type: START_LOADER });
};

export const stopLoader = () => (dispatch) => {
  dispatch({ type: STOP_LOADER });
};
