import { makeStyles, createStyles } from '@material-ui/core/styles';

const searchFormStyle = makeStyles((theme) =>
  createStyles({
    selectProvider: {
      marginTop: '10px',
    },
    calendarContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    searchFormWrapper: {
      marginTop: '10px',
      display: 'inline-flex',
      flexFlow: 'column',
    },
    calendarWrapper: {
      borderRadius: '4px',
      margin: '0 15px 15px 0 ',
      border: '1px solid rgba(0, 0, 0, 0.23)',
      color: 'rgba(0, 0, 0, 0.87)',
      padding: '18.5px 14px',
      flex: '0 0 29%',
      width: '187px',
      height: '56px',
      boxSizing: 'border-box',
      [theme.breakpoints.down('xs')]: {
        // flex: '0 0 0',
        width: '100%',
      },
      // '&:nth-child(1)': {
      //   marginRight: '10px',
      // },
    },
    searchBlockCont: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      [theme.breakpoints.down('xs')]: {
        flexFlow: 'column',
      },
    },
    searchSubmitBtn: {
      width: '120px',
      [theme.breakpoints.down('xs')]: {
        margin: '0 0 20px 0',
        width: '100%',
      },
    },
    searchItem: {
      flex: '0 0 29%',
      width: '187px',
      margin: '0 15px 15px 0 ',
      [theme.breakpoints.down('xs')]: {
        flex: '0 0 0',
        width: '100%',
      },
    },
    searchBlock: {
      width: '60%',
      display: 'flex',
      flexWrap: 'wrap',
      marginBottom: '20px',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        flexFlow: 'column',
      },
    },
    formControl: {
      // width: '100%',
      // paddingLeft: '30px',
      // paddingRight: '100px',
    },
  })
);

export default searchFormStyle;
