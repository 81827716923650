import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { FormControl } from '@material-ui/core';
import {
  ProviderFields,
  AdminFields,
  userDetailsTranslate,
  immutableProperties,
} from 'constants/main';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import useStyles from './userEditStyle.js';

const UserEdit = ({
  currentUser,
  handleChangeUserEdit,
  selectedRole,
  handleSelect,
  errorMessages,
  refprops,
  createUser,
}) => {
  const classes = useStyles();
  const getFields = () => {
    switch (selectedRole) {
      case 2:
        return ProviderFields;
      case 1:
        return AdminFields;
      default:
        return [];
    }
  };

  const itemGrid = selectedRole === 1 ? 12 : 6;

  const checkError = (item) => {
    return !!errorMessages.length && errorMessages.includes(item);
  };

  const checkFieldError = (item) => {
    if (checkError(item))
      return (
        <FormHelperText>
          {item &&
            `Поле ${userDetailsTranslate[item]} обязательно для заполнения`}
        </FormHelperText>
      );

    return null;
  };

  return (
    <div>
      <FormControl ref={refprops}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
        >
          {getFields().map((item, idx) => {
            if (immutableProperties.includes(item) && !createUser) {
              return (
                <Grid
                  key={idx}
                  item
                  sm={itemGrid}
                  className={classes.gridWrapper}
                >
                  <FormControl key={idx} className={classes.formControlWrapper}>
                    <TextField
                      variant="outlined"
                      disabled
                      name={item}
                      className={classes.userEditTextField}
                      label={userDetailsTranslate[item]}
                      defaultValue={currentUser[item] || ''}
                      onChange={(e) => handleChangeUserEdit(e)}
                      id={item}
                    />
                    {checkFieldError(item)}
                  </FormControl>
                </Grid>
              );
            } else if (item === 'roleName') {
              return (
                <Grid
                  item
                  sm={itemGrid}
                  key={idx}
                  className={classes.gridWrapper}
                >
                  <FormControl
                    className={classes.formControlWrapper}
                    variant="outlined"
                    error={checkError(item)}
                  >
                    <InputLabel id={item}>Роль</InputLabel>
                    <Select
                      id={item}
                      className={classes.userEditTextField}
                      value={selectedRole}
                      error={checkError(item)}
                      onChange={(e) => handleSelect(e.target.value, item)}
                      label={userDetailsTranslate[item]}
                      SelectDisplayProps={{ 'data-testid': 'selectRole' }}
                    >
                      <MenuItem value={2}>Провайдер</MenuItem>
                      <MenuItem value={1}>Администратор</MenuItem>
                    </Select>
                    {checkFieldError(item)}
                  </FormControl>
                </Grid>
              );
            } else if (item === 'smsSending' || item === 'backUrlSending') {
              return (
                <Grid
                  item
                  sm={itemGrid}
                  key={idx}
                  className={classes.gridWrapper}
                >
                  <FormControl
                    variant="outlined"
                    className={classes.formControlWrapper}
                    error={checkError(item)}
                  >
                    <InputLabel id={item}>
                      {userDetailsTranslate[item]}
                    </InputLabel>
                    <Select
                      defaultValue={
                        typeof currentUser !== 'undefined' &&
                        typeof currentUser[item] === 'number'
                          ? currentUser[item]
                          : ''
                      }
                      onChange={(e) => handleSelect(e.target.value, item)}
                      label={userDetailsTranslate[item]}
                      className={classes.userEditTextField}
                      id={item}
                    >
                      <MenuItem value={1}>да</MenuItem>
                      <MenuItem value={0}>нет</MenuItem>
                    </Select>
                    {checkFieldError(item)}
                  </FormControl>
                </Grid>
              );
            } else if (item === 'providerName') {
              return null;
            } else {
              return (
                <Grid
                  item
                  sm={itemGrid}
                  key={idx}
                  className={classes.gridWrapper}
                >
                  <FormControl
                    error={checkError(item)}
                    className={classes.formControlWrapper}
                  >
                    <TextField
                      variant="outlined"
                      name={item}
                      className={classes.userEditTextField}
                      error={checkError(item)}
                      label={userDetailsTranslate[item]}
                      defaultValue={currentUser[item] || ''}
                      onChange={(e) => handleChangeUserEdit(e)}
                      id={item}
                    />
                    {checkFieldError(item)}
                  </FormControl>
                </Grid>
              );
            }
          })}
        </Grid>
      </FormControl>
    </div>
  );
};

UserEdit.propTypes = {
  currentUser: PropTypes.object.isRequired,
  handleChangeUserEdit: PropTypes.func.isRequired,
  selectedRole: PropTypes.number.isRequired,
  handleSelect: PropTypes.func.isRequired,
  errorMessages: PropTypes.array.isRequired,
  refprops: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  createUser: PropTypes.bool.isRequired,
};

export default UserEdit;
