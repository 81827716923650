export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
export const SET_ALERT_MESSAGE = 'SET_ALERT_MESSAGE';

export const setAlertMessage = (message, status) => (dispatch) => {
  dispatch({
    type: SET_ALERT_MESSAGE,
    alertMessage: message,
    isAlertMessage: status,
  });
};
export const setRequestError = (isRequestError, errorMessage) => (dispatch) => {
  dispatch({
    type: SET_ERROR_MESSAGE,
    isRequestError,
    errorMessage,
  });
};
