import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { sendAuthReq } from 'actions/api';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    background: 'white',
    padding: '20px',
    boxShadow: '0 8px 12px rgba(0,0,0,0.08)',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  passBlock: {
    justifyContent: 'space-between',
  },
  containerWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '83vh',
    width: '100vw',
    justifyContent: 'center',
  },
  cashOutLogo: {
    width: '30px',
    margin: '15px',
  },
}));

const SignIn = (props) => {
  const [authData, setAuthData] = useState({});
  const [errorInput, setErrorInput] = useState(false);
  const classes = useStyles();

  const handleClick = () => {
    if (
      authData.login &&
      authData.login !== '' &&
      authData.password &&
      authData.password !== ''
    ) {
      props.sendAuthReq(authData, 1);
      setErrorInput(false);
    } else {
      setErrorInput(true);
    }
  };

  const handleChange = (e) => {
    let tempAuthData = {
      ...authData,
      [e.target.name]: e.target.value.trim(),
    };
    setAuthData(tempAuthData);
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      className={classes.containerWrapper}
    >
      <CssBaseline />
      <div className={classes.paper}>
        <form className={classes.form} noValidate>
          <Typography variant="body1" color="textSecondary">
            Вход в кабинет
          </Typography>
          <TextField
            autoComplete="off"
            error={errorInput && authData.login === ''}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="login"
            label="Логин"
            name="login"
            onChange={(e) => handleChange(e)}
            autoFocus
            inputProps={{ 'aria-label': 'login' }}
          />
          <TextField
            autoComplete="off"
            variant="outlined"
            error={errorInput && authData.password === ''}
            margin="normal"
            onChange={(e) => handleChange(e)}
            required
            fullWidth
            name="password"
            label="Пароль"
            type="password"
            id="password"
            inputProps={{ 'aria-label': 'password' }}
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleClick}
          >
            Войти
          </Button>
        </form>
      </div>
    </Container>
  );
};

SignIn.propTypes = {
  sendAuthReq: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ sendAuthReq }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
