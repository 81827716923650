import jwt from 'jsonwebtoken';
import { setToLocalStorage } from 'helpers/cashout';
import { api } from 'instance/axiosIntanse';
import {
  hadleUserListRequest,
  setRequestList,
  setProviderList,
} from 'actions/users';
import { redirectToPage } from 'actions/navigation';
import { setRequestError, setAlertMessage } from 'actions/snackbar';
import { PUBLIC_JWT_KEY, ADMIN_ROLE } from 'constants/main';

export const GET_ACCESS_TOKEN = 'GET_ACCESS_TOKEN';
export const SET_USER_AUTHORIZED = 'SET_USER_AUTHORIZED';

export const setUserAuthorized = (status) => (dispatch) => {
  dispatch({ type: 'SET_USER_AUTHORIZED', isAuthorized: status });
};

export const getCurrentUser = () => (dispatch) => {
  return api.get(`/admin/current-user`).then((res) => {
    const { data: responseData, statusCode } = res.data;
    if (statusCode === 200) {
      setToLocalStorage('currentUser', responseData.user);

      dispatch(
        redirectToPage(
          responseData.user.roleID === ADMIN_ROLE ? 'admin' : 'requests'
        )
      );
    }
    return res;
  });
};

export const sendAuthReq =
  ({ login, password }, type) =>
  (dispatch) => {
    return api
      .post(`/authorize`, {
        authType: type,
        login: login,
        password: password,
      })
      .then(({ data }) => {
        const { data: response, statusCode } = data;

        if (statusCode === 200) {
          jwt.verify(response.token, PUBLIC_JWT_KEY, (err, decoded) => {
            if (err === null && typeof decoded !== 'undefined') {
              setToLocalStorage('token', response.token);
              dispatch(setUserAuthorized(true));
              dispatch(getCurrentUser());
            } else {
              if (err.name === 'TokenExpiredError') {
                dispatch(
                  setRequestError(
                    true,
                    'Ошибка авторизации. Проверьте часовой пояс, дату и время на своем ПК, они должны быть актуальны'
                  )
                );
              } else {
                dispatch(setRequestError(true, 'Ошибка авторизации'));
              }
            }
          });
        }
        return data;
      });
  };

export const resendCallbackUrl = (cashOutRequestID) => (dispatch) => {
  return api
    .post(`/admin/cash-out-request/resend-callback/${cashOutRequestID}`)
    .then((res) => {
      dispatch(showAlertForSuccessReponse(res));
      return res;
    });
};

export const resendSmsCallback = (cashOutRequestID) => (dispatch) => {
  return api
    .post(`/admin/cash-out-request/resend-sms/${cashOutRequestID}`)
    .then((res) => {
      const { message, statusCode } = res.data;
      if (statusCode === 200) {
        dispatch(setAlertMessage(message || '', true));
      }
      return res;
    });
};

export const requestCancel = (cashOutRequestID) => (dispatch) => {
  return api
    .post(`/admin/cash-out-request/cancel/${cashOutRequestID}`)
    .then((res) => {
      dispatch(showAlertForSuccessReponse(res));
      return res;
    });
};

export const showAlertForSuccessReponse = (res) => (dispatch) => {
  const { message, statusCode } = res.data;
  if (statusCode === 200) {
    dispatch(setAlertMessage(message, true));
  }
};

export const activateUser = (userID) => (dispatch) => {
  dispatch(changeUserStatus(userID, 'activate'));
};

export const deactivateUser = (userID) => (dispatch) => {
  dispatch(changeUserStatus(userID, 'deactivate'));
};

export const changeUserStatus = (userID, status) => (dispatch) => {
  return api.patch(`/admin/users/${userID}/${status}`).then((res) => {
    const { message, statusCode } = res.data;
    if (statusCode === 200) {
      dispatch(setAlertMessage(message, true));
      dispatch(getUserList(hadleUserListRequest));
      return res;
    }
  });
};

export const getUserList = (responseHandle) => (dispatch) => {
  return api.get('/admin/users').then(({ data }) => {
    const { data: responseData, statusCode } = data;
    if (statusCode === 200) {
      dispatch(responseHandle(responseData.users));
    }
    return data;
  });
};

export const editUserProfile = (id) => (dispatch, getState) => {
  let editedData = getState().cashOutReducer.editedUserInfo;

  return api.patch(`/admin/users/${id}`, editedData).then((res) => {
    const { statusCode } = res.data;
    if (statusCode === 200) {
      dispatch(getUserList(hadleUserListRequest));
      dispatch(setAlertMessage('Информация о пользователе сохранена', true));
    }
    return res;
  });
};

export const createUserProfile = () => (dispatch, getState) => {
  let editedData = getState().cashOutReducer.editedUserInfo;
  return api.post(`/admin/users/`, editedData).then((res) => {
    const { statusCode } = res.data;
    if (statusCode === 200) {
      dispatch(getUserList(hadleUserListRequest));
      dispatch(setAlertMessage('Пользователь успешно создан', true));
    }
    return res;
  });
};

// providerID: '324'
export const getRequestList = () => (dispatch, getState) => {
  let searchRequestsData = getState().cashOutReducer.searchRequestsData;
  return api
    .post(`/admin/cash-out-request/list`, searchRequestsData)
    .then((res) => {
      const { data: responseData, statusCode } = res.data;
      if (statusCode === 200) {
        dispatch(setRequestList(responseData));
      }
      return res;
    });
};

export const getProviders = () => (dispatch) => {
  return api.get(`/admin/dictionaries/cash-out-request`).then((res) => {
    const { data: responseData, statusCode } = res.data;
    if (statusCode === 200) {
      dispatch(setProviderList(responseData.providers));
    }
    return res;
  });
};
