import { GET_ACCESS_TOKEN } from 'actions/api';

const getDefaultState = () => ({
  isAuthenticated: false,
});

const auth = (state = getDefaultState(), action = {}) => {
  if (action.type === GET_ACCESS_TOKEN)
    return { ...state, isAuthenticated: action.isAuthenticated };

  return state;
};

export default auth;
