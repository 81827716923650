import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from 'reducers/index';
import { interceptorInstance } from 'instance/axiosIntanse';
import thunk from 'redux-thunk';
import customMiddleware from 'middlewares/customMiddleware';

const configureStore = (initialState) => {
  const middleware = [];
  const enhancers = [];
  const actionCreators = {};
  middleware.push(thunk);
  middleware.push(customMiddleware);

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Options: http://zalmoxisus.github.io/redux-devtools-extension/API/Arguments.html
        actionCreators,
      })
    : compose;
  enhancers.push(applyMiddleware(...middleware));
  const enhancer = composeEnhancers(...enhancers);
  const store = createStore(rootReducer, initialState, enhancer);
  interceptorInstance(store);
  return store;
};

export default configureStore;
