import { makeStyles, createStyles } from '@material-ui/core/styles';

const HeaderStyle = makeStyles((theme) =>
  createStyles({
    headerWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0px 40px',
      [theme.breakpoints.down('xs')]: {
        padding: '0 0 0 10px',
        justifyContent: 'flex-start',
      },
    },
    burgerButton: {
      display: 'none',
      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
    },
    menuWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    headerLinks: {
      display: 'flex',
    },
    chipWrapper: {
      position: 'relative',
      [theme.breakpoints.down('xs')]: {
        padding: '10px 0 0 10px',
      },
    },
    menuIcon: {
      color: '#ffffff',
    },

    headerLink: {
      '&:hover': {
        color: '#FFCC05',
      },
      textDecoration: 'none',
      color: '#ffffff',
      marginLeft: '10px',
      marginRight: '20px',
      [theme.breakpoints.down('xs')]: {
        color: 'rgba(0, 0, 0, 0.87)',
      },
    },
    activeHeaderLink: {
      color: '#FFCC05',
    },
    headerLogo: {
      width: '124.55px',
      height: '40px',
      marginLeft: '30px',
      marginRight: '40px',
    },
    logoWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    navBar: {
      width: '100%',
      textAlign: 'left',
    },
  })
);

export default HeaderStyle;
