import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

function Copyright() {
  const year = new Date().getFullYear();

  return (
    <Typography variant="body2" color="textSecondary">
      © ТОО «Личная касса»
      <br />
      {`2020 - ${year}`}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => {
  return {
    root: {},
    main: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(2),
    },
    footer: {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      bottom: '0',
      width: '100%',
      padding: '8px 0',
      backgroundColor:
        theme.palette.type === 'light'
          ? theme.palette.grey[200]
          : theme.palette.grey[800],
    },
  };
});

export default function Footer() {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Container maxWidth="sm">
        <Copyright />
      </Container>
    </footer>
  );
}
